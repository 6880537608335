'use client'
import {useEffect} from "react";
import markerSDK from "@marker.io/browser";

export const MarkerIO = () => {
  useEffect(() => {
    markerSDK.loadWidget({
      project: process.env.NEXT_PUBLIC_MARKER_IO_API_KEY || '',
    });
  })
  return <></>
}